import * as Sentry from "@sentry/browser";
import App, { AppProps } from "next/app";
import { analytics, basicAuth } from "@project/shared";
import { StickyContainer } from "react-sticky";
import { useEffect } from "react";
import { useRouter } from "next/router";
import Head from "next/head";
import "react-multi-carousel/lib/styles.css";
import "../utils/styles.css";
import { QueryClient, QueryClientProvider } from "react-query";
import dynamic from "next/dynamic";
import { TopHeader } from "@project/shared/src/components/organism/TopHeader";
import { TopFooter } from "../component/molecules/TopFooter";
import { FloatFooter } from "../component/molecules/FloatFooter";
import { ConfigProvider } from "antd";
import jaJP from "antd/lib/locale/ja_JP";
import { FloatingRegistrationButton } from "../component/molecules/FloatingRegistrationButton";
const LPFooter = dynamic(() => import("@project/shared/src/components/organism/LPFooter"));
const LPHeader = dynamic(() => import("@project/shared/src/components/organism/LPHeader"));

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV !== "development",
    environment: `consumer-${process.env.NODE_ENV}`,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN
  });
}

const queryClient = new QueryClient();
const excludedPaths = ["/contact-registration", "/contact-reserve", "/download"];

const MyApp = ({
  Component,
  pageProps
}: AppProps) => {
  const routers = useRouter();
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      const logEvent = (url: string) => {
        analytics().setCurrentScreen(url);
        analytics().logEvent("ScreenView", {
          screen_name: url,
          app_name: "coworkation-consumer"
        });
      };

      routers.events.on("routeChangeComplete", url => {
        window.scrollTo(0, 0);
        logEvent(url);
      });
      logEvent(window.location.pathname);
      return () => {
        routers.events.off("routeChangeComplete", logEvent);
      };
    }
  }, []);
  useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, []);
  return <div>
      <QueryClientProvider client={queryClient}>
        <StickyContainer>
          <Head>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />

            <link href="https://cdn.jsdelivr.net/npm/yakuhanjp@3.4.1/dist/css/yakuhanjp.min.css" rel="stylesheet" />

            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css" />

            <link href="https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+New:wght@400;500;700&display=swap" rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap" rel="stylesheet" />
          </Head>
          {routers.pathname === "/reloclub" ? <LPHeader /> : <TopHeader />}
          <ConfigProvider locale={jaJP}>
            <Component {...pageProps} />
          </ConfigProvider>
          {routers.pathname === "/reloclub" ? <LPFooter /> : <>
              <TopFooter />
              {!excludedPaths.some(p => p == routers.pathname) && <FloatFooter />}
            </>}
          {!excludedPaths.some(p => p == routers.pathname) && <FloatingRegistrationButton />}
        </StickyContainer>
      </QueryClientProvider>
    </div>;
};

MyApp.getInitialProps = async appContext => {
  const {
    req,
    res
  } = appContext.ctx;
  const appProps = await App.getInitialProps(appContext);

  if (process.env.NEXT_PUBLIC_ENVIRONMENT === "development" && req && res) {
    basicAuth(req, res);
  }

  if (req?.headers?.host.match(/^www/) !== null) {
    res?.writeHead(301, {
      location: "https://" + req.headers.host.replace(/^www./, "") + req.url
    });
    res?.end();
  }

  if (req?.headers?.host.match(/^xn--rckdn6qkb1a3db.com/) !== null) {
    res?.writeHead(301, {
      location: "https://co-workation.com" + req.url
    });
    res?.end();
  }

  return { ...appProps
  };
};

export default MyApp;